.highlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px 32px;
  margin-bottom: 32px;
}

.highlightCard {
  border: var(--border);
  border-radius: 8px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  padding: 24px;
}

.highlightCardTitle {
  color: var(--black);
  line-height: 1.1;
  font-size: 1.44rem;
  font-family: var(--font-family);
  font-weight: 700;
  letter-spacing: -0.25px;
  margin: 0;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  transform: translate(-3px, 0);
}

.cardHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 8px;
  position: relative;
}

.cardHead a {
  font-size: 14px;
  text-decoration: none;
}

.cardBodySummary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.cardBodySummary div {
  line-height: 24px;
}

.cardBodySummary div:last-child {
  font-size: 1rem;
  font-weight: 700;
}

.cardBodyActive,
.cardBodyClosed {
  display: grid;
  grid-template-rows: 1fr;
  gap: 8px;
  grid-template-columns: 24px 1fr 88px;
  align-items: center;
}

.cardBodyActive svg,
.cardBodyClosed svg {
  border-radius: 50%;
}

.cardBodyProposal {
  font-size: 0.9rem;
  line-height: 24px;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}