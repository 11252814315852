.hero {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr;
  gap: 64px;
  align-items: center;
  justify-items: flex-end;
  min-height: calc(100svh - 144px);
  padding: 32px 16px;
  position: relative;
}

.content {
  z-index: 1;
}

.content span {
  display: block;
  color: var(--medium);
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.hero h1 {
  margin-bottom: 16px;
}

.hero p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 32px;
}

@media screen and (min-width: 968px) {
  .hero {
    grid-template-columns: minmax(280px, 560px) 1fr;
    grid-template-rows: 1fr;
  }
}