.main {
  min-height: 100svh;
}

.section:first-child {
  border-bottom: 0.5px solid;
  border-image-slice: 1;
  border-width: 0.5px;
  border-image-source: var(--border-image-source);
}

.wrapper {
  max-width: var(--max-width);
  margin-inline: auto;
}

.daos {
  padding: 80px 16px;
}

.errorPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100svh - 80px);
  padding: 80px 16px;
}

.errorPage div {
  font-size: 1.2rem;
  max-width: 600px;
  text-align: center;
  margin-bottom: 24px;
}

.errorPage h2 {
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 16px;
}

.errorPageBtn {
  border-radius: 100px;
  background-color: var(--dark-blue);
  color: var(--white);
  cursor: pointer;
  font-weight: 600;
  cursor: pointer;
  width: 168px;
  padding: 12px 24px;
  text-align: center;
}